/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-debugger */
import { Box, Card, createStyles, Text, Tabs, Table, Badge, Center, Pagination, Tooltip } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { getCoverageInfo, getClaimInfo } from '../../fhirApi';
import { useMedplum } from '@medplum/react';
import { showNotification } from '@mantine/notifications';
import { IconEye } from '@tabler/icons-react';

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    height: '100%',
  },

  section: {
    padding: theme.spacing.md,
  },

  footer: {
    padding: theme.spacing.md,
    borderTop: `1px solid #EEF1F6 !important`,
  },

  header: {
    padding: theme.spacing.md,
    borderBottom: `1px solid #EEF1F6 !important`,
  },

  icon: {
    padding: theme.spacing.xs,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#EDF5FF',
    height: '40px',
    width: '40px',
    borderRadius: '25px',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[5],
  },

  scrollbar: {
    overflow: 'hidden',
    '&:hover': {
      overflowY: 'auto',
      scrollbarWidth: 'thin',
      scrollbarColor: '#ccc #f3f3f3',
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#ccc',
        borderRadius: '8px',
        '&:hover': {
          background: '#aaa',
        },
      },
    },
  },

  label: {
    fontWeight: 400,
    fontSize: theme.fontSizes.sm,
    marginRight: theme.spacing.sm,
  },

  table: {},

  tableRow: {
    padding: '30px',
  },

  highlitedRow: {
    color: '#1B5390 !important',
    background: 'none !important',
  },

  dBlock: {
    [theme.fn.smallerThan('sm')]: {
      display: 'block',
    },
  },

  tabs: {
    '& .mantine-Tabs-tab': {
      fontSize: '14px',
      color: '#5D6874',
      marginRight: '10px',
      fontWeight: 400,
      borderRadius: '0.25rem !important',
      padding: '0.30rem 0.35rem !important',
    },
    '& button[data-active]': {
      color: '#000',
      background: '#C9E1FF !important',
      backgroundColor: '#C9E1FF !important',
      borderBottom: 'none',
      fontWeight: 400,
      borderRadius: '0.25rem !important',
      padding: '0.30rem 0.35rem !important',
    },
  },

  tabPanel: {
    display: 'block',
    flexDirection: 'row',
    alignItems: 'center',
  },

  badge: {
    minWidth: 'auto',
    minHeight: '25px',
    color: '#000000',
    textTransform: 'capitalize',
    fontWeight: 600,
    padding: '0 0.8rem',
  },

  pagination: {
    '& button': {
      border: 'none',
    },
    '& button[data-active]': {
      color: '#1b5390  ',
      background: 'none !important',
      backgroundColor: 'none !important',
      borderBottom: '0px !important',
    },
  },
}));

export function Insurance() {
  const { classes } = useStyles();
  const patientID = useParams().id;
  const medplum = useMedplum();
  const [data, setData] = useState([]);
  const [noDataRow, setNoDataRow] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const detailsTab = [
    { value: 'coverage', name: 'Coverage' },
    { value: 'claim', name: 'Claim' },
  ];

  const [activeTab, setActiveTab] = useState(detailsTab[0].value);
  const [isLoading, setIsLoading] = useState(true);

  const coverageFields = [
    { fieldName: 'planName', displayName: 'Plan Name' },
    { fieldName: 'groupName', displayName: 'Group Name' },
    { fieldName: 'subscriber', displayName: 'Subscriber' },
    { fieldName: 'beneficiary', displayName: 'Beneficiary' },
    { fieldName: 'payor', displayName: 'Payor' },
    { fieldName: 'relationship', displayName: 'Relationship' },
    { fieldName: 'period', displayName: 'Period' },
    { fieldName: 'status', displayName: 'Status' },
  ];

  const claimFields = [
    { fieldName: 'claimNumber', displayName: 'Claim Number' },
    { fieldName: 'billablePeriod', displayName: 'Billable Period' },
    { fieldName: 'insurer', displayName: 'Insurer' },
    { fieldName: 'provider', displayName: 'Provider' },
    { fieldName: 'diagnosis', displayName: 'Diagnosis' },
    { fieldName: 'paymentDate', displayName: 'Payment Date' },
    { fieldName: 'paymentAmount', displayName: 'Payment Amount' },
    { fieldName: 'benefitAmount', displayName: 'Benefit Amount' },
    { fieldName: 'copayAmount', displayName: 'Copay Amount' },
    { fieldName: 'submittedAmount', displayName: 'Submitted Amount' },
    { fieldName: 'status', displayName: 'Status' },
  ];

  const successStatus = ['active', 'completed', 'fulfilled', 'final'];
  const failedStatus = ['inactive', 'incomplete', 'cancelled'];
  const warningStatus = ['booked'];
  const infoStatus = ['amended'];
  const blueColorStatus = ['arrived'];

  const statusButton = (status: any) => {
    const formattedStatus = status?.toLowerCase();
    return successStatus.includes(formattedStatus) ? (
      <Badge className={classes.badge} variant="filled" style={{ background: '#DEF5EA' }}>
        {status}
      </Badge>
    ) : failedStatus.includes(formattedStatus) ? (
      <Badge className={classes.badge} variant="filled" style={{ background: '#FAEBEB' }}>
        {status}
      </Badge>
    ) : warningStatus.includes(formattedStatus) ? (
      <Badge className={classes.badge} variant="filled" style={{ background: '#FCF2DF' }}>
        {status}
      </Badge>
    ) : infoStatus.includes(formattedStatus) ? (
      <Badge className={classes.badge} variant="filled" style={{ background: '#F2F2F2' }}>
        {status}
      </Badge>
    ) : blueColorStatus.includes(formattedStatus) ? (
      <Badge className={classes.badge} variant="filled" style={{ background: '#DBF0FD' }}>
        {status}
      </Badge>
    ) : (
      '-'
    );
  };

  useEffect(() => {
    CoverageInfoData();
  }, [activeTab]);

  const displayData = data?.slice(startIndex, endIndex);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const generateTabContent = (tab: any, data: any[], fields: any[]) => {
    return (
      <Tabs.Panel value={tab.value} pt="md" className={classes.tabPanel}>
        <Card withBorder radius="md" className={classes.card} style={{ overflow: 'auto' }}>
          <Card.Section className={classes.header} p="lg">
            <Text className="darkText" fw={700}>
              {tab.name}
            </Text>
          </Card.Section>
          <Card.Section className={classes.section} p="lg">
            <Table highlightOnHover style={{ overflowX: 'hidden' }} className={classes.table} verticalSpacing="sm">
              <thead>
                <tr className={classes.tableRow}>
                  {fields.map(({ fieldName, displayName }) => (
                    <th className={classes.tableRow} key={fieldName}>
                      {displayName}
                    </th>
                  ))}
                  <th className={classes.tableRow}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr className={classes.tableRow}>
                    <td className={classes.tableRow} colSpan={fields.length}>
                      <Center>{'Loading...'}</Center>
                    </td>
                  </tr>
                ) : noDataRow ? (
                  <tr className={classes.tableRow}>
                    <td className={classes.tableRow} colSpan={fields.length}>
                      <Center>{'No Data Found'}</Center>
                    </td>
                  </tr>
                ) : (
                  data.map((item: any) => (
                    <tr className={classes.tableRow} key={item.id}>
                      {fields.map(({ fieldName }) => (
                        <td className={classes.tableRow} key={fieldName}>
                          {fieldName === 'status' ? (
                            <>{statusButton(item[fieldName])}</>
                          ) : (
                            <>{item[fieldName] || '-'}</>
                          )}
                        </td>
                      ))}
                      <td className={classes.tableRow}>
                        <Tooltip label="View" withArrow>
                          <Link
                            style={{
                              textDecoration: 'none',
                              color: '#3673b6',
                              padding: '5px',
                              fontSize: '11px',
                              fontWeight: 700,
                              borderRadius: '2px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                            target="_blank"
                            to={`/${tab.name === 'Claim' ? 'ExplanationOfBenefit' : tab.name}/${item.id}/details`}
                          >
                            <IconEye size={20} />
                          </Link>
                        </Tooltip>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
          </Card.Section>
        </Card>
      </Tabs.Panel>
    );
  };

  const CoverageInfoData = () => {
    getCoverageInfo(medplum, patientID)
      .then((response: any) => {
        if (response.data && response.data.length > 0) {
          const tempList = response.data.map(
            ({
              Id,
              Status,
              Subscriber,
              Period,
              Beneficiary,
              Relationship,
              Payor,
              Group,
              Plan,
            }: {
              Id: string;
              Status: string;
              Subscriber: string;
              Period: string;
              Beneficiary: string;
              Relationship: string;
              Payor: string;
              Group: string;
              Plan: string;
            }) => ({
              id: Id,
              status: Status,
              subscriber: Subscriber,
              period: Period,
              beneficiary: Beneficiary,
              relationship: Relationship,
              payor: Payor,
              groupName: Group,
              planName: Plan,
            })
          );
          setData(tempList);
        } else {
          setNoDataRow(true);
          setData([]);
        }
      })
      .catch((error: any) => {
        console.error('Error fetching coverage data:', error);
        showNotification({ color: 'red', message: 'Failed to export' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const ClaimInfoData = () => {
    getClaimInfo(medplum, patientID)
      .then((response: any) => {
        if (response.data) {
          let tempList: any = [];
          tempList = response.data?.map((item: any) => ({
            id: item.id,
            claimNumber: item.ClaimNumber,
            billablePeriod: item?.BillablePeriod,
            paymentDate: item?.PaymentDate,
            insurer: item?.Insurer,
            provider: item.Provider,
            diagnosis: item?.Diagnosis,
            paymentAmount: item?.PaymentAmount,
            benefitAmount: item?.insuranceAmounts?.benefit,
            copayAmount: item?.insuranceAmounts?.copay,
            submittedAmount: item?.insuranceAmounts?.submitted,
            status: item?.Status,
          }));
          if (tempList.length) {
            setData(tempList);
          } else {
            setNoDataRow(true);
            setData([]);
          }
        }
      })
      .catch((error: any) => {
        console.error('Error fetching data:', error);
        showNotification({ color: 'red', message: 'Failed to export' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleTabChange = (tab: any) => {
    setData([]);
    setActiveTab(tab);
    setIsLoading(true);
    setCurrentPage(1);
    if (tab === 'coverage') {
      CoverageInfoData();
    } else if (tab === 'claim') {
      ClaimInfoData();
    }
  };

  return (
    <Box py="sm" display="block">
      <Tabs
        variant="pills"
        defaultValue={activeTab}
        className={classes.tabs}
        onTabChange={(newTab) => handleTabChange(newTab)}
      >
        <Tabs.List style={{ whiteSpace: 'nowrap', flexWrap: 'nowrap' }}>
          {detailsTab.map((tab) => (
            <Tabs.Tab key={tab.value} value={tab.value}>
              {tab.name}
            </Tabs.Tab>
          ))}
        </Tabs.List>
        {generateTabContent(detailsTab[0], displayData, coverageFields)}
        {generateTabContent(detailsTab[1], displayData, claimFields)}
      </Tabs>
      <Center mt="lg" style={{ justifyContent: 'flex-end' }}>
        <Pagination
          value={currentPage}
          total={Math.ceil(data.length / itemsPerPage)}
          onChange={handlePageChange}
          defaultValue={itemsPerPage}
          className={classes.pagination}
        />
      </Center>
    </Box>
  );
}

export default Insurance;
