/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Grid, createStyles  } from '@mantine/core';
import { IntroductionPanel } from '../IntroductionPanel';
import RegisterForm from './RegisterForm';

const useStyles = createStyles((theme) => ({
    hiddenMobile: {
      [theme.fn.smallerThan('md')]: {
        display: 'none',
      },
    },
  
    paddingMobile: {
      [theme.fn.smallerThan('sm')]: {
        padding: '25px !important',
      },
    },
}));

const PractitionerRegister = () => {
    const {classes} = useStyles();
  return (
    <>
      <Grid style={{ background: '#CFEFF2' }}>
        <Grid.Col md={6} lg={6}
            style={{ alignContent: 'center', justifyContent: 'center', minHeight: '100vh' }}
            className={classes.hiddenMobile}
        >
            <IntroductionPanel />
        </Grid.Col>
        <Grid.Col md={5} lg={5} className={classes.paddingMobile}>
            <RegisterForm />
        </Grid.Col>
      </Grid>
    </>
  );
};

export default PractitionerRegister;
