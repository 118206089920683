import { Anchor, Box, Button, Checkbox, Divider, Group, PasswordInput, Stack, TextInput } from '@mantine/core';
import {
  BaseLoginRequest,
  GoogleCredentialResponse,
  GoogleLoginRequest,
  LoginAuthenticationResponse,
  normalizeOperationOutcome,
} from '@medplum/core';
import { OperationOutcome } from '@medplum/fhirtypes';
import { useMedplum } from '@medplum/react-hooks';
import { ReactNode, useCallback, useState } from 'react';
import { Form } from '../Form/Form';
import { GoogleButton } from '../GoogleButton/GoogleButton';
import { getGoogleClientId } from '../GoogleButton/GoogleButton.utils';
import { OperationOutcomeAlert } from '../OperationOutcomeAlert/OperationOutcomeAlert';
import { getErrorsForInput, getIssuesForExpression } from '../utils/outcomes';

export interface AuthenticationFormProps extends BaseLoginRequest {
  readonly disableEmailAuth?: boolean;
  readonly disableGoogleAuth?: boolean;
  readonly onForgotPassword?: () => void;
  readonly onRegister?: () => void;
  readonly handleAuthResponse: (response: LoginAuthenticationResponse) => void;
  readonly children?: ReactNode;
}

export function AuthenticationForm(props: AuthenticationFormProps): JSX.Element {
  const [email, setEmail] = useState<string>();

  if (!email) {
    return <EmailForm setEmail={setEmail} {...props} />;
  } else {
    return <PasswordForm email={email} {...props} />;
  }
}

export interface EmailFormProps extends BaseLoginRequest {
  readonly disableEmailAuth?: boolean;
  readonly disableGoogleAuth?: boolean;
  readonly onRegister?: () => void;
  readonly handleAuthResponse: (response: LoginAuthenticationResponse) => void;
  readonly setEmail: (email: string) => void;
  readonly children?: ReactNode;
  readonly onForgotPassword?: () => void;
}

export function EmailForm(props: EmailFormProps): JSX.Element {
  const { onRegister, handleAuthResponse, onForgotPassword, children, disableEmailAuth, ...baseLoginRequest } = props;
  const medplum = useMedplum();
  const googleClientId = !props.disableGoogleAuth && getGoogleClientId(props?.googleClientId);
  const [outcome, setOutcome] = useState<OperationOutcome>();
  const issues = getIssuesForExpression(outcome, undefined);

  const isExternalAuth = useCallback(
    async (authMethod: any): Promise<boolean> => {
      if (!authMethod.authorizeUrl) {
        return false;
      }

      const state = JSON.stringify({
        ...(await medplum.ensureCodeChallenge(baseLoginRequest)),
        domain: authMethod.domain,
      });
      const url = new URL(authMethod.authorizeUrl);
      url.searchParams.set('state', state);
      window.location.assign(url.toString());
      return true;
    },
    [medplum, baseLoginRequest]
  );

  const handleSubmit = useCallback(
    (formData: Record<string, string>) => {
      medplum
        .startLogin({
          ...baseLoginRequest,
          password: formData.password,
          remember: formData.remember === 'on',
          email: formData.email,
        })
        .then(handleAuthResponse)
        .catch((err: unknown) => setOutcome(normalizeOperationOutcome(err)));
    },
    [medplum, baseLoginRequest, handleAuthResponse]
  );

  const handleGoogleCredential = useCallback(
    async (response: GoogleCredentialResponse) => {
      try {
        const authResponse = await medplum.startGoogleLogin({
          ...baseLoginRequest,
          googleCredential: response.credential,
        } as GoogleLoginRequest);
        if (!(await isExternalAuth(authResponse))) {
          handleAuthResponse(authResponse);
        }
      } catch (err) {
        setOutcome(normalizeOperationOutcome(err));
      }
    },
    [medplum, baseLoginRequest, isExternalAuth, handleAuthResponse]
  );

  return (
    <Form style={{ maxWidth: 400 }} onSubmit={handleSubmit}>
      <h1 style={{ flexDirection: 'column', textAlign: 'left', marginTop: '30px', marginBottom: '5px' }}>{children}</h1>
      <OperationOutcomeAlert issues={issues} />
      <h4 style={{ textAlign: 'center', color: '#000000', marginTop: '5px', fontWeight: 400 }}>Sign in to your provider account to access real-time patient data & AI-driven insights.</h4>
      {googleClientId && (
        <>
          <Group position="center" p="xl" style={{ height: 70 }}>
            <GoogleButton googleClientId={googleClientId} handleGoogleCredential={handleGoogleCredential} />
          </Group>
          {!disableEmailAuth && <Divider label="or" labelPosition="center" my="lg" />}
        </>
      )}
      {!disableEmailAuth && (
        <div>
          <TextInput
            name="email"
            type="email"
            label="Username"
            placeholder="Enter your Email Id"
            required={true}
            autoFocus={true}
            error={getErrorsForInput(outcome, 'email')}
            style={{ margin: '25px 0 20px' }}
          />
          <Stack spacing="xl">
            <PasswordInput
              name="password"
              label="Password"
              autoComplete="off"
              placeholder='Enter your Password'
              required={true}
              autoFocus={true}
              error={getErrorsForInput(outcome, 'password')}
            />
          </Stack>
        </div>
      )}
      <Group position="apart" mt="md" className='d-flex' spacing={0}>
        {onForgotPassword && (
          <Anchor component="button" style={{ fontWeight: '600', color: 'var(--primary-color)', fontSize: '14px' }} type="button" onClick={onForgotPassword} size="xs">
            Forgot password?
          </Anchor>
        )}
      </Group>
      <div style={{ marginTop: '20px' }}>
        <Button type="submit" style={{ width: '100%', backgroundColor: '#009A98', color: '#fff' }}>
          Sign in
        </Button>
      </div>
      <Box ta={'center'} mt="md">
        Don't have an account?
        <Anchor
          component="button"
          style={{
            fontWeight: '400',
            color: '#009A98',
            fontSize: '15px',
            marginLeft: '5px',
          }}
          onClick={onRegister}
        >
          Register
        </Anchor>
      </Box>
    </Form>
  );
}

export interface PasswordFormProps extends BaseLoginRequest {
  readonly email: string;
  readonly onForgotPassword?: () => void;
  readonly handleAuthResponse: (response: LoginAuthenticationResponse) => void;
  readonly children?: ReactNode;
}

export function PasswordForm(props: PasswordFormProps): JSX.Element {
  const { onForgotPassword, handleAuthResponse, ...baseLoginRequest } = props;
  const medplum = useMedplum();
  const [outcome, setOutcome] = useState<OperationOutcome>();
  const issues = getIssuesForExpression(outcome, undefined);

  const handleSubmit = useCallback(
    (formData: Record<string, string>) => {
      medplum
        .startLogin({
          ...baseLoginRequest,
          password: formData.password,
          remember: formData.remember === 'on',
        })
        .then(handleAuthResponse)
        .catch((err: unknown) => setOutcome(normalizeOperationOutcome(err)));
    },
    [medplum, baseLoginRequest, handleAuthResponse]
  );

  return (
    <Form onSubmit={handleSubmit}>
      {/* <Center style={{ flexDirection: 'column' }}>{children}</Center> */}
      <OperationOutcomeAlert issues={issues} />
      <Stack spacing="xl">
        <PasswordInput
          name="password"
          label="Password"
          autoComplete="off"
          placeholder='Enter your Password'
          required={true}
          autoFocus={true}
          error={getErrorsForInput(outcome, 'password')}
        />
      </Stack>
      <Group position="apart" className='d-flex' spacing={0} noWrap>
        <Checkbox id="remember" name="remember" label="Remember me" size="xs" />
        {onForgotPassword && (
          <Anchor component="button" style={{ fontWeight: '600', color: '#475467', fontSize: '13px' }} type="button" onClick={onForgotPassword} size="xs">
            Forgot password
          </Anchor>
        )}
      </Group>
      <div style={{ marginTop: '20px' }}>
        <Button type="submit" className='w-100 sign-btn'>Sign in</Button>
      </div>
    </Form>
  );
}
