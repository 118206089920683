import { Box, Grid } from '@mantine/core';
import { OrganisationCard } from './OrganisationCard';
import NotificationsCard from './NotificationsCard';
import FeaturedCards from './FeaturedCards';
import TitleComponent from './TitleComponent';
import { getNotifications, statisticCounts } from '../fhirApi';
import { useMedplum } from '@medplum/react';
import { useEffect, useState } from 'react';

export function Dashboard(): JSX.Element {
  const medplum = useMedplum();
  const [statisticCountsRes, setStatisticCountsRes] = useState<any | null>();
  const [notificationList, setNotificationList] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await statisticCounts(medplum);
        setStatisticCountsRes(response?.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
    notificationsList();
  }, []);

  const notificationsList = () => {
    getNotifications(medplum).then((response) => {
      setNotificationList(response?.data.entry);
    }).catch((error) => {
      console.log(error)
    })
  }

  const cardsData = [
    {
      ImageSrc: '../../img/dashboard-assets/square-plus.svg',
      text: 'Organizations',
      subText: 'Total Organizations Registered',
      counts: `${statisticCountsRes?.data?.organizationCounts || '00'}`,
      routeTo: '#',
      cardTheme: 'yellow-card',
      progressHighlight: '#E1CEA8',
      progressBg: '#F4E9D3',
      isCheckCard: false
    },
    // {
    //   ImageSrc: '../../img/dashboard-assets/appointments.svg',
    //   text: 'Appointments',
    //   subText: 'Total # of Appointments',
    //   counts: `${statisticCountsRes?.data?.appointmentCounts || '00'}`,
    //   routeTo: '#',
    //   cardTheme: 'green-card',
    //   progressHighlight: '#8AD8C7',
    //   progressBg: '#D3FAF2',
    //   isCheckCard: false
    // },
    // {
    //   ImageSrc: '../../img/dashboard-assets/procedures.svg',
    //   text: 'Procedures',
    //   subText: 'Total # of Procedures',
    //   counts: `${statisticCountsRes?.data?.procedureCounts || '00'}`,
    //   routeTo: '#',
    //   cardTheme: 'gray-card',
    //   progressHighlight: '#ADADAD',
    //   progressBg: '#DBDBDB',
    //   isCheckCard: false
    // },
    {
      ImageSrc: '../../img/dashboard-assets/patients.svg',
      text: 'Patients',
      subText: 'Total # of Patients Registered',
      counts: `${statisticCountsRes?.data?.patientCounts || '00'}`,
      routeTo: '/patients',
      cardTheme: 'blue-card',
      progressHighlight: '#82A4D4',
      progressBg: '#D7E6FB',
      isCheckCard: true
    },
    {
      ImageSrc: '../../img/dashboard-assets/providers-connected.svg',
      text: 'Practitioners',
      subText: 'Total # of Practitioners Connected',
      counts: `${statisticCountsRes?.data?.providerCounts || '00'}`,
      routeTo: '/practitioners',
      cardTheme: 'purple-card',
      progressHighlight: '#9581D4',
      progressBg: '#E2DAFD',
      isCheckCard: true
    },
    {
      ImageSrc: '../../img/dashboard-assets/chip-spark.svg',
      text: 'EHRs Integrated',
      subText: 'Total # of EHRs Integrated',
      counts: `${statisticCountsRes?.data?.totalEhrRegistered || '00'}`,
      routeTo: '/EHRs-integrated',
      cardTheme: 'red-card',
      progressHighlight: '#DAA2A2',
      progressBg: '#FFE4E4',
      isCheckCard: true
    },
  ];

  return (
    <>
        <TitleComponent title="Dashboard" />

        <Box px="lg" py="sm">
          <Grid>
            <Grid.Col span={12} sm={12} lg={12}>
              <FeaturedCards cardsData={cardsData} />
            </Grid.Col>
          </Grid>
        </Box>

        <Box px="lg" py="sm">
          <Grid mb="md">
            <Grid.Col span={12} sm={12} lg={8}>
              <OrganisationCard />
            </Grid.Col>
            <Grid.Col span={12} sm={12} lg={4}>
              <NotificationsCard notificationList={notificationList}/>
            </Grid.Col>
          </Grid>
        </Box>
    </>
  );
}
