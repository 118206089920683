import { Space } from '@mantine/core';
import { MEDPLUM_VERSION, MedplumClient } from '@medplum/core';
import { UserConfiguration } from '@medplum/fhirtypes';
import { AppShell, Loading, NavbarMenu, useMedplum } from '@medplum/react';
import { Suspense, useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { AppRoutes } from './AppRoutes';
import { getOrganization } from './fhirApi';
import './App.css';

import { UserConfigurationMenuLink } from '@medplum/fhirtypes';

interface CustomUserConfigurationMenuLink extends UserConfigurationMenuLink {
  subLinks?: {
    label: string;
    href: string;
    icon?: string;
    target?: string;
    name?: string;
  }[];
}

interface NavbarLink {
  label: string;
  href: string;
  icon?: JSX.Element;
  name?: string;
  target?: string;
}

export function App(): JSX.Element {
  const medplum = useMedplum();
  const config = medplum.getUserConfiguration();
  const location = useLocation();
  const navigate = useNavigate();
  const historyStack = useRef<string[]>([]);
  const [searchParams] = useSearchParams();
  const isLoggedIn = localStorage.getItem('isLoggedIn');
  const [imageURL, setImageURL] = useState<any>('/img/my-wellness-id-logo.png');
  useEffect(() => {
    if (isLoggedIn) {
      medplum
        .get('auth/me')
        .then((response: any) => {
          const orgId = response.profile?.managingOrganization?.reference.split('/')[1];
          if (orgId) {
            getOrganization(medplum, orgId)
              .then((response: any) => {
                if (response.data?.extension) {
                  response.data?.extension.map((e: any) => {
                    if (e.url === 'http://organization.image') {
                      setImageURL(e.valueString);
                    }
                  });
                }
              })
              .catch((error: any) => {
                console.error('Error fetching data:', error);
              });
          }
        })
        .catch((err) => console.log(err));
    }
  }, [isLoggedIn]);

  useEffect(() => {
    const handlePopState = () => {
      if (historyStack.current.length > 1) {
        historyStack.current.pop(); // Remove current page
        const previousPage = historyStack.current[historyStack.current.length - 1]; // Get previous page
        navigate(previousPage, { replace: true });
      }
    };
  
    // Ensure historyStack only updates when location changes
    if (
      historyStack.current.length === 0 ||
      historyStack.current[historyStack.current.length - 1] !== location.pathname
    ) {
      historyStack.current.push(location.pathname);
    }
  
    window.addEventListener('popstate', handlePopState);
  
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [location.pathname, navigate]);  

  if (medplum.isLoading()) {
    return <Loading />;
  }

  return (
    <AppShell
      logo={<img src={imageURL ? imageURL : '/img/my-wellness-id-logo.png'} style={{ height: '38px' }} />}
      pathname={location.pathname}
      searchParams={searchParams}
      version={MEDPLUM_VERSION}
      menus={userConfigToMenu(config, medplum)}
      displayAddBookmark={!!config?.id}
    >
      <Suspense fallback={<Loading />}>
        <AppRoutes />
      </Suspense>
    </AppShell>
  );
}

function userConfigToMenu(config: UserConfiguration | undefined, medplum: MedplumClient): NavbarMenu[] {
  const currentUrl = window.location.href.toLowerCase();
  if ((currentUrl.includes("organization")) && !medplum.isSuperAdmin()) {
    console.log('Redirecting to home');
    window.location.href = window.location.origin;
    return [];
  }
  return (
    config?.menu?.map((menu) => {
      return {
        title: menu.title || '',
        links: (menu.link as CustomUserConfigurationMenuLink[]).map((link) => {
          const isClinicalTrials = link.name === 'ClinicalTrials';
          return {
            label: getDisplayName(link.name as string).displayName,
            href: isClinicalTrials ? '#' : link.target as string,
            icon: getIcon(link.name as string) || <span />,
            subLinks: (link.subLinks || []).map((subLink) => {
              return {
                label: getDisplayName(subLink.name as string).displayName,
                href: subLink.target || '#', 
                icon: subLink.icon ? (
                  <img src={subLink.icon} alt="" style={{ height: '16px', width: '16px' }} />
                ) : getIcon(subLink.name as string) || <span />,
              };
            }) as NavbarLink[],
          };
        }) as NavbarLink[],
      };
    }) || []
  );
}

const resourceTypeToIcon: Record<string, any> = {
  Dashboard: '../../img/dashboard-assets/IconHome.svg',
  Organization: '../../img/dashboard-assets/IconOrganization.svg',
  Practitioner: '../../img/dashboard-assets/IconPractitioners.svg',
  Patients: '../../img/dashboard-assets/IconUser.svg',
  Claims: '../../img/dashboard-assets/IconInsurrance.svg',
  Locations: '../../img/dashboard-assets/IconLocations.svg',
  Settings: '../../img/dashboard-assets/IconSettings.svg',
  Patient: '../../img/dashboard-assets/IconUser.svg',
  Appointment: '../../img/dashboard-assets/ic_appointment.svg',
  Medication: '../../img/dashboard-assets/ic_medication.svg',
  Observation: '../../img/dashboard-assets/ic_observation.svg',
  Procedure: '../../img/dashboard-assets/ic_procedure.svg',
  CarePlan: '../../img/dashboard-assets/ic_careplan.svg',
  Goal: '../../img/dashboard-assets/ic_goal.svg',
  Immunization: '../../img/dashboard-assets/ic_immunization.svg',
  DiagnosticReport: '../../img/dashboard-assets/ic_digno_report.svg',
  CareTeam: '../../img/dashboard-assets/ic_careteam.svg',
  AccessPolicy: '../../img/dashboard-assets/ic_access.svg',
  Project: '../../img/dashboard-assets/ic_project.svg',
  Batch: '../../img/dashboard-assets/ic_batch.svg',
  SuperConfig: '../../img/dashboard-assets/ic_superConfig.svg',
  Security: '../../img/dashboard-assets/ic_security.svg',
  Filters: '../../img/dashboard-assets/filter.svg',
  EhrIntegration: '../../img/dashboard-assets/sso-white.svg',
  MywellnessAI: '../../img/dashboard-assets/fhir-bot-white.svg',
  Assessment: '../../img/dashboard-assets/assessment_white.svg',
  AIConsultations: '../../img/dashboard-assets/ai -consult.svg',
  Telehealth: '../../img/dashboard-assets/telehealth.svg',
  ClinicalTrials: '../../img/dashboard-assets/ic_clinical_trials.svg',
  ClinicalTrialList: '../../img/dashboard-assets/ic_clinical_trials.svg',
  EligibilityChecker: '../../img/dashboard-assets/ic_clinical_trials.svg',
  InvitePatients: '../../img/dashboard-assets/ic_clinical_trials.svg',
  TrialQualifiedPatients: '../../img/dashboard-assets/ic_clinical_trials.svg',
  ConsentTracker: '../../img/dashboard-assets/ic_clinical_trials.svg',
  Notifications: '../../img/dashboard-assets/ic_clinical_trials.svg',
};

function getIcon(name: string): JSX.Element | undefined {
  try {
    if (name in resourceTypeToIcon) {
      const Icon = resourceTypeToIcon[name];
      return <img src={Icon} style={{ height: '16px', marginRight: '10px', color: '#FFF', width: '20px' }} />;
    } else if (name === 'Claims & Benefits') {
      const Icon = resourceTypeToIcon['Claims'];
      return <img src={Icon} style={{ height: '16px', marginRight: '10px', color: '#FFF', width: '20px' }} />;
    }
  } catch (e) {
    // Ignore
  }
  return <Space w={30} />;
}

const resourceTypeToDisplayName: Record<string, string> = {
  Dashboard: 'Dashboard',
  Organization: 'Organizations',
  Practitioner: 'Practitioners',
  Patients: 'Patients',
  Claims: 'Claims & Benefits',
  Locations: 'Locations',
  Settings: 'Settings',
  Patient: 'Patients',
  Appointment: 'Appointments',
  Medication: 'Medications',
  Observation: 'Observations',
  Procedure: 'Procedures',
  CarePlan: 'Care Plans',
  Goal: 'Goals',
  Immunization: 'Immunizations',
  DiagnosticReport: 'Diagnostic Reports',
  CareTeam: 'Care Teams',
  AccessPolicy: 'Access Policy',
  Project: 'Project',
  Batch: 'Batch',
  SuperConfig: 'Super Config',
  Security: 'Security',
  Filters: 'Advanced Search',
  EhrIntegration: 'EHR Integration',
  MywellnessAI: 'My Wellness AI',
  Assessment: 'Assessments',
  AIConsultations: 'AI Consultations',
  Telehealth: 'Telehealth',
  ClinicalTrials: 'AI Clinical Trials',
  ClinicalTrialList: 'Clinical Trial List',
  EligibilityChecker: 'Eligibility Checker',
  InvitePatients: 'Invite Patients',
  TrialQualifiedPatients: 'Trial Qualified Patients',
  ConsentTracker: 'Consent Tracker',
  Notifications: 'Notifications',
};

function getDisplayName(name: string) {
  let displayName = name;
  if (name in resourceTypeToDisplayName) {
    displayName = resourceTypeToDisplayName[name];
  }
  return { displayName };
}
